<template>
    <div class="Replenishment">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="6" style="margin-right: 35px">
                        <ef-biz-code v-model="form.code" />
                    </el-col>
                    <el-col :span="14">
                        <ef-dept-type-and-dept-select
                            @selectDeptType="setDeptType"
                            @selectDept="setDept"
                            :componentLoadingCompleteFlag.sync="pageComponentLoadingCompleteFlag"
                        />
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <el-col :span="6">
                        <el-form-item label="单据状态">
                            <el-select v-model="form.repayDeptGoodsStatus" filterable>
                                <el-option label="请选择" value="" />
                                <el-option label="待入库" :value="1" />
                                <el-option label="已入库" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="6">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="审核状态">
                            <el-select v-model="form.reviewStatus" value="reviewStatus" clearable>
                                <el-option label="待审核" value="0" />
                                <el-option label="已审核" value="2" />
                                <el-option label="已拒绝" value="-1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="showReviewDialog"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.export')"
                >导出
            </el-button>
            <el-button
                type="primary"
                @click="openExportDeptRepayGoodsDetailsDialog"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.export')"
                >导出待补货清单
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                id="printMe"
                :data="tableData"
                ref="table"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="单据号" width="240" prop="code" v-if="showColumn('code')" />
                <el-table-column label="补货机构" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="审核状态" width="100" prop="reviewStatusDesc">
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatusDesc }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="100"
                    prop="repayDeptGoodsStatus"
                    key="repayDeptGoodsStatus"
                    v-if="showColumn('repayDeptGoodsStatus')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.repayDeptGoodsStatusDesc }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="实际补货数量"
                    width="160"
                    prop="totalRepayGoodsCount"
                    v-if="showColumn('totalRepayGoodsCount')"
                />
                <el-table-column
                    label="入库时间"
                    width="160"
                    prop="repayGoodsTime"
                    v-if="showColumn('repayGoodsTime')"
                />
                <el-table-column
                    label="入库操作人"
                    width="120"
                    prop="repayOperatorName"
                    v-if="showColumn('repayOperatorName')"
                />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.repay.goods.show')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            :disabled="scope.row.disableEditBtn"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.repay.goods.edit')"
                        >
                            编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="changeSingleBizStock(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.repay.goods.stock')"
                            :disabled="scope.row.disableChangeStockBtn"
                            >入库
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.repay.goods.delete')"
                            :disabled="scope.row.disableDeleteBtn"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
        <el-dialog
            title="请选择需要导出补货时间的起止范围"
            :visible.sync="exportDeptRepayGoodsDetailsDialogVisible"
            width="40%"
            top="100px"
            @close="closeExportDeptRepayGoodsDetailsDialog"
        >
            <div>
                <el-date-picker
                    v-model="exportRepayGoodsStartDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="开始日期"
                ></el-date-picker>
                至
                <el-date-picker
                    v-model="exportRepayGoodsEndDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="结束日期"
                ></el-date-picker>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeExportDeptRepayGoodsDetailsDialog" size="small">取 消</el-button>
                <el-button type="primary" @click="exportDeptRepayGoodsDetails" size="small">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import EfBizCode from 'components/EfBizCode';
import StockBizCommon from 'js/StockBizCommon';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfPagination from 'components/EfPagination';
import EfReviewBiz from 'components/EfReviewBiz';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';

export default {
    name: 'Replenishment',
    components: {
        EfReviewBiz,
        EfPagination,
        EfEndDate,
        EfStartDate,
        EfBizCode,
        CheckTableShowColumnDialog,
        EfDeptTypeAndDeptSelect,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            selectedBizArr: [],
            baseUrl: this.$efApi.replenishmentApi.baseUrl,
            form: {
                code: null,
                deptCode: null,
                endCreateTime: '',
                limit: 100,
                page: 1,
                repayDeptGoodsStatus: '',
                reviewStatus: '',
                endTime: Util.nowFormatDate(),
                abstractCode: '',
                startCreateTime: '',
            },
            status: '',
            total: 0,
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,
            tableData: [],
            reArr: [], //补货机构
            exportDeptRepayGoodsDetailsDialogVisible: false,
            exportRepayGoodsStartDate: null,
            exportRepayGoodsEndDate: null,
            pageComponentLoadingCompleteFlag: false,
        };
    },
    computed: {},
    mounted() {
        Util.setIntervalAndTimeout(
            () => {
                return this.pageComponentLoadingCompleteFlag;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        changeMultipleBizStock() {
            const codes = this.selectedBizArr.map((e) => {
                return e.code;
            });
            this.changeStock(codes);
        },
        changeSingleBizStock(row) {
            this.changeStock([row.code]);
        },
        changeStock(codes) {
            const _this = this;
            UrlUtils.PostRemote(
                _this,
                `/repayDeptGoods/repayGoods/${codes}`,
                {
                    codes: codes,
                    message: '确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!',
                },
                null,
                () => {
                    this.$message.success('入库成功');
                    this.handleQuery();
                }
            );
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        showReviewDialog() {
            this.$refs.review.open(this.selectedBizArr);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            if (!this.pageComponentLoadingCompleteFlag) {
                //只要有一个false 就代表有组件未加载完成
                return;
            }
            this.form.page = 1;
            this.queryList();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },

        handleCreate() {
            Util.nameJump(this, 'menu.mall.manage.repay.goods.create', ['电商管理', '门店补货管理', '新建补货单']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.mall.manage.repay.good.detail', ['电商管理', '门店补货管理', '补货单详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.mall.manage.repay.goods.edit', ['电商管理', '门店补货管理', '编辑补货单'], {
                form: row,
                code: row.code,
            });
        },

        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);
            if (this.$refs.table.selection.length == 0) {
                this.$message.warning('请至少勾选一项导出');
                return;
            }
            UrlUtils.Export(this, '门店补货管理', '/repayDeptGoods/exportExtend', this.form, codes);
        },
        openExportDeptRepayGoodsDetailsDialog() {
            this.exportDeptRepayGoodsDetailsDialogVisible = true;
        },
        closeExportDeptRepayGoodsDetailsDialog() {
            this.exportDeptRepayGoodsDetailsDialogVisible = false;
            this.exportRepayGoodsStartDate = null;
            this.exportRepayGoodsEndDate = null;
        },
        exportDeptRepayGoodsDetails() {
            if (!this.exportRepayGoodsStartDate || !this.exportRepayGoodsEndDate) {
                this.$message.error('请选择开始日期和结束日期');
                return false;
            }
            UrlUtils.Export(
                this,
                null,
                '/repayDeptGoods/exportDeptRepayGoodsDetails',
                {
                    repayGoodsStartDate: this.exportRepayGoodsStartDate,
                    repayGoodsEndDate: this.exportRepayGoodsEndDate,
                },
                ''
            );
        },

        async queryList() {
            const rst = await this.$efApi.replenishmentApi.page(this.form);
            this.tableData = rst.list.map((e) => {
                return {
                    ...e,
                    wholeSaleMoneySum: 0,
                    //根据状态设置操作按钮禁用或启用
                    disableEditBtn: !(e.reviewStatus === 0 && e.repayDeptGoodsStatus === 1),
                    disableDeleteBtn: !(e.reviewStatus === 0 && e.repayDeptGoodsStatus === 1),
                    disableReviewBtn: e.reviewStatus != 0,
                    disableChangeStockBtn: !(e.reviewStatus === 2 && e.repayDeptGoodsStatus === 1),
                };
            });
            this.total = rst.count;
        },
        async handleDelete(row) {
            await this.$efApi.replenishmentApi.deleteBiz(row.code);
            this.handleQuery();
        },
    },
};
</script>

<style scoped>
.Replenishment .el-form-item {
    margin-bottom: 0;
}
</style>
